export default function Texts({
  title,
  subtitle,
  loading,
}: {
  title: string;
  subtitle: string;
  loading?: boolean;
}) {
  return (
    <div className="pa-texts">
      <div className="pa-title pa-white pa-mon">
        <h1>{title}</h1>
      </div>
      <div className="pa-subtitle pa-white pa-Inter">
        <p>{subtitle}</p>
      </div>

      {loading ? (
        <div className="pa-loading">
          <div className="pa-loading-dots dot-1"></div>
          <div className="pa-loading-dots dot-2"></div>
          <div className="pa-loading-dots dot-3"></div>
        </div>
      ) : null}
    </div>
  );
}
