import { Background, MainTexts } from 'components';

export default function home() {
  return (
    <div className="pa-main">
      <Background />
      <MainTexts title="Pelleaval" subtitle="coming soon" loading />
    </div>
  );
}
